import { Select, Table } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BranchMember, fetchBranchMembers } from "../../apis/branch.api";
import { getSalesData } from "../../apis/dasboad.api";
import { fetchProFormaInvoices } from "../../apis/pro_forma_invoice.api";
import { daysInThisMonth } from "../../helpers/date_helper";

const SalesUserIDs = [
  "21d388f6-1b74-4d40-967e-142af1c72a0a", // Arfaat
  "31fd5d70-9dd0-4b19-a49a-dafd7da488d4", // Kousika
  "e8a03e3f-d6cf-40b9-b9f5-ca6ce0228bbd", // Padmashri
  "92cb4494-8bfd-4f92-a36a-843d6fdb79b1", // Prasanna
  "5e9aaa9a-f85d-4d86-9f8b-24bda5cb0c83", // Dileep Kumar
  "2897edea-6e6d-4d39-a22c-70a96fbe79b9", // Ashish
  "3d7c4a0f-704f-4e37-8661-3e76327f6375", // Sowbik
  "404f8ca6-0cca-4acc-9883-fd0498804dc0", // Sarthak
  "bd29266c-c428-4f89-8eee-8a08e2bcfa8b", // Manish
  "bd5e81d3-ae6f-4d06-a397-adfdec9b6068", // Lisha
  "bff91511-dcf6-4b21-8211-eaf34884133b", // Vikas
  "31b9e970-9a73-46a1-a48e-ca3ac7951a42", // Fareed
  "d39d4933-2acb-410c-b09a-fdc4c3bc14ba", // Debarpita
  "8399221d-dbaa-4d73-a321-370c476ba6ca", // Kousia Krishnasami
  "7293719c-e006-464c-8803-67693d46070a", // Sumukh
  "ab9d2ce7-ed7c-44ef-b514-e39ee116adec", // Tejas
];

const toHideIds = [
  // Sarthak, Manish, Debarpita, Padmashri, Arfaat, Sumukh, Lisha
  "404f8ca6-0cca-4acc-9883-fd0498804dc0",
  // "bd29266c-c428-4f89-8eee-8a08e2bcfa8b",
  "d39d4933-2acb-410c-b09a-fdc4c3bc14ba",
  // "e8a03e3f-d6cf-40b9-b9f5-ca6ce0228bbd",
  // "21d388f6-1b74-4d40-967e-142af1c72a0a",
  "8399221d-dbaa-4d73-a321-370c476ba6ca",
  "7293719c-e006-464c-8803-67693d46070a",
  "bd5e81d3-ae6f-4d06-a397-adfdec9b6068",
];

const targets = {
  // Kousika
  "31fd5d70-9dd0-4b19-a49a-dafd7da488d4": 40_00_000,
  // Fareed
  "31b9e970-9a73-46a1-a48e-ca3ac7951a42": 35_00_000,
  // Lisha
  "bd5e81d3-ae6f-4d06-a397-adfdec9b6068": 35_00_000,
  // Ashish
  "2897edea-6e6d-4d39-a22c-70a96fbe79b9": 30_00_000,
  // Dileep Kumar
  "5e9aaa9a-f85d-4d86-9f8b-24bda5cb0c83": 30_00_000,
  // Vikas
  "bff91511-dcf6-4b21-8211-eaf34884133b": 15_00_000,
  // Sowbik
  "3d7c4a0f-704f-4e37-8661-3e76327f6375": 15_00_000,
  // Prasanna
  "92cb4494-8bfd-4f92-a36a-843d6fdb79b1": 15_00_000,
  // Manish
  "bd29266c-c428-4f89-8eee-8a08e2bcfa8b": 1,
  // Arfaat
  "21d388f6-1b74-4d40-967e-142af1c72a0a": 20_00_000,
  // Padmashri
  "e8a03e3f-d6cf-40b9-b9f5-ca6ce0228bbd": 20_00_000,
  // Tejas
  "ab9d2ce7-ed7c-44ef-b514-e39ee116adec": 15_00_000,
  // Vikas
  "465504cb-cbd0-4304-af94-f5c6637fd5f5": 15_00_000,
} as Record<string, number>;

export function SalesLeaderboard() {
  const [orgUsers, setOrgUsers] = useState<BranchMember[]>([]);
  const [selectedUser, setSelectedUser] = useState<BranchMember | null>(null);
  const [monthlyData, setMonthlyData] = useState<Record<string, any[]>>({});
  const [previousDayData, setPreviousDayData] = useState<Record<string, any[]>>(
    {}
  );
  const [loadedUsers, setLoadedUsers] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);
  const [sortParam, setSortParam] = useState<
    "monthly-acheived-percentage" | "previous-day-acheived-percentage"
  >("monthly-acheived-percentage");

  const [assignedToLeftOverMap, setAssignedToLeftOverMap] = useState<{
    monthly: Record<string, any[]>;
    previousDay: Record<string, any[]>;
  }>({ monthly: {}, previousDay: {} });

  const today = new Date();
  // Expected: (Current day / No. of days in the month) * Monthly target
  const expectedAcheivment = today.getDate() / daysInThisMonth();
  const expectedAcheivmentPercent = expectedAcheivment * 100;

  const [view, setView] = useState<"backend" | "frontend">("backend");

  const [salesData, setSalesData] = useState<
    Record<
      string,
      {
        current_month_estimates_sum: number;
        today_estimates_sum: number;
        business_manager_name: string;
        count: number;
      }
    >
  >({});

  useEffect(() => {}, []);

  const leftOverUserIds = useMemo(() => {
    const loadedUserIds = Array.from(
      new Set([...Object.keys(monthlyData), ...Object.keys(previousDayData)])
    );
    return Array.from(
      new Set([
        ...Object.keys(assignedToLeftOverMap.monthly),
        ...Object.keys(assignedToLeftOverMap.previousDay),
      ])
    ).filter((userId) => !loadedUserIds.includes(userId));
  }, [
    assignedToLeftOverMap.monthly,
    assignedToLeftOverMap.previousDay,
    monthlyData,
    previousDayData,
  ]);

  const userIdToNameMap = useMemo(() => {
    return orgUsers.reduce((acc, user) => {
      acc[user.user.id] = user.user.f_name;
      return acc;
    }, {} as Record<string, string>);
  }, [orgUsers]);

  const fetchEstimateInfoByPage = useCallback(
    async (
      params: Record<string, any> = {},
      store: "monthly" | "previous-day"
    ): Promise<unknown> => {
      const response = await fetchProFormaInvoices(params);
      if (response) {
        const fn = store === "monthly" ? setMonthlyData : setPreviousDayData;
        const resultsWithAssignedToDifferentThanCreatedByAndNotNull =
          response.results.filter(
            (result: any) =>
              result?.assigned_to !== null &&
              !result.created_by.includes(result?.assigned_to?.f_name)
          );

        setAssignedToLeftOverMap((prev: any) => {
          const prevCopy = { ...prev };
          resultsWithAssignedToDifferentThanCreatedByAndNotNull.forEach(
            (result: any) => {
              if (store === "monthly") {
                if (prevCopy.monthly[result?.assigned_to?.id]) {
                  // Do not insert this record if it already exists
                  if (
                    prevCopy.monthly[result?.assigned_to?.id].find(
                      (r: any) => r.id === result.id
                    )
                  ) {
                    return;
                  }
                  prevCopy.monthly[result?.assigned_to?.id].push(result);
                } else {
                  prevCopy.monthly[result?.assigned_to?.id] = [result];
                }
              } else {
                if (prevCopy.previousDay[result?.assigned_to?.id]) {
                  // Do not insert this record if it already exists
                  if (
                    prevCopy.previousDay[result?.assigned_to?.id].find(
                      (r: any) => r.id === result.id
                    )
                  ) {
                    return;
                  }
                  prevCopy.previousDay[result?.assigned_to?.id].push(result);
                } else {
                  prevCopy.previousDay[result?.assigned_to?.id] = [result];
                }
              }
            }
          );

          console.log(prevCopy);

          return prev;
        });

        fn((prevData) => {
          if (prevData[params.created_by]) {
            return {
              ...prevData,
              [params.created_by]: [
                ...prevData[params.created_by],
                ...response.results.filter(
                  (result: any) =>
                    (result.created_by.includes(
                      String(result?.assigned_to?.f_name)
                    ) ||
                      result?.assigned_to === null) &&
                    !prevData[params.created_by].find(
                      (r: any) => r.id === result.id
                    )
                ),
              ],
            };
          } else {
            return {
              ...prevData,
              [params.created_by]: response.results.filter(
                (result: any) =>
                  result.created_by.includes(
                    String(result?.assigned_to?.f_name)
                  ) || result?.assigned_to === null
              ),
            };
          }
        });

        if (response.next) {
          // Get the page from the query params of the url
          const url = new URL(response.next);
          const page = url.searchParams.get("page");
          return fetchEstimateInfoByPage({ ...params, page }, store);
        } else {
          setLoadedUsers((prev) => {
            const newSet = new Set(prev);
            newSet.add(params.created_by);
            return newSet;
          });
          setLoading(false);
          return response.results;
        }
      } else {
        return false;
      }
    },
    []
  );

  const fetchOrgUsers = useCallback(async () => {
    const members = await fetchBranchMembers();
    if (members) {
      setOrgUsers(members);
    }
  }, []);

  const fetchData = useCallback(
    async (userId: string) => {
      // We will receive paginated data from the API
      // We will fetch the data for the selected user

      if (!userId) {
        return;
      }

      if (
        (monthlyData[userId] ||
          (monthlyData[userId] as Array<any>)?.length > 0) &&
        (previousDayData[userId] ||
          (previousDayData[userId] as Array<any>)?.length > 0)
      ) {
        return;
      }

      setLoading(true);

      const monthFirstDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      const currentDate = new Date();
      const yesterday = new Date(new Date().setDate(currentDate.getDate() - 1));

      return await Promise.all([
        fetchEstimateInfoByPage(
          {
            created_by: userId,
            order_placed_time__gte: dayjs(monthFirstDate).format("YYYY-MM-DD"),
            // order_placed_time__lte: dayjs(currentDate).format("YYYY-MM-DD"),
            estimate_status__in:
              "order_placed,order_confirmed,partly_shipped,shipped,partly_delivered,delivered",
          },
          "monthly"
        ),
        fetchEstimateInfoByPage(
          {
            created_by: userId,
            order_placed_time__gte: dayjs(yesterday).format("YYYY-MM-DD"),
            // order_placed_time__lte: dayjs(currentDate).format("YYYY-MM-DD"),
            estimate_status__in:
              "order_placed,order_confirmed,partly_shipped,shipped,partly_delivered,delivered",
          },
          "previous-day"
        ),
      ]);
    },
    [monthlyData, previousDayData, fetchEstimateInfoByPage]
  );

  useEffect(() => {
    if (view === "backend") {
      (async () => {
        const data = await getSalesData();
        setSalesData(data);
      })();
      return;
    }

    // Frontend - This is inefficient!!!

    // Sequentially fetch data for SalesIDs
    const fetchSequentially = async () => {
      for (const userId of SalesUserIDs) {
        await fetchData(userId);
      }
    };

    fetchSequentially();
  }, [view]);

  useEffect(() => {
    fetchOrgUsers();
  }, [fetchOrgUsers]);

  const columns = useMemo(() => {
    return [
      {
        key: "sno",
        title: "Rank",
        dataIndex: "sno",
      },
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
      },
      {
        key: "monthly-target",
        title: "Monthly Target",
        dataIndex: "monthly-target",
      },
      {
        key: "monthly-achieved",
        title: () => {
          const start = dayjs().startOf("month").format("hh a DD MMM YYYY");
          return (
            <>
              Monthly Achieved{" "}
              <div style={{ fontSize: 10 }} className="text-secondary">
                {start} &mdash; Now
              </div>
            </>
          );
        },
        dataIndex: "monthly-achieved",
      },
      {
        key: "lead-lag",
        title: "Lead/Lag",
        render: function LeadLag(record: Record<string, string | number>) {
          const leadLag =
            parseFloat(record["monthly-achieved"].toString()) -
            expectedAcheivment *
              parseFloat(record["monthly-target"].toString());
          return leadLag >= 0 ? `+${leadLag.toFixed(2)}` : leadLag.toFixed(2);
        },
      },
      {
        key: "monthly-acheivement-percentage",
        title: (
          <>
            Monthly Achievement %
            <div style={{ fontSize: 10 }}>
              Expected: {expectedAcheivmentPercent.toFixed(2)}%
            </div>
          </>
        ),
        render: function MonthlyAcheivementPercentage(
          record: Record<string, number | string>
        ): string {
          return `${record["monthly-acheived-percentage"]}%` as string;
        },
      },
      {
        key: "previous-day-target",
        title: "Daily Target",
        dataIndex: "previous-day-target",
      },
      {
        key: "previous-day-achieved",
        dataIndex: "previous-day-achieved",
        title: () => {
          // Start is yesterday 00:00
          const start = dayjs()
            .subtract(1, "day")
            .startOf("day")
            .format("hh a DD MMM YYYY");
          return (
            <>
              Daily Achieved{" "}
              <div style={{ fontSize: 10 }} className="text-secondary">
                {start} &mdash; Now
              </div>
            </>
          );
        },
      },
      {
        key: "previous-day-acheivement-percentage",
        title: "Daily Achievement %",
        render: function DailyAcheivementPercentage(
          record: Record<string, number | string>
        ): string {
          return `${record["previous-day-acheived-percentage"]}%`;
        },
      },
    ];
  }, [expectedAcheivmentPercent, expectedAcheivment]);

  return (
    <main className="p-4">
      <section className="bg-white rounded p-4">
        <div className="th-font-size-24 fw-bold mb-3">Sales Dashboard</div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div
            className="d-flex align-items-center mb-4"
            style={{ gap: "1rem" }}
          >
            {view === "frontend" && leftOverUserIds.length > 0 && (
              <div>
                Pending load users:
                {leftOverUserIds.map((userId) => (
                  <span
                    className="ms-2 text-primary text-underline"
                    onClick={() => {
                      fetchData(userId);
                    }}
                  >
                    {userIdToNameMap[userId]}
                  </span>
                ))}
              </div>
            )}
            {view === "frontend" &&
              Object.keys(monthlyData).length !== SalesUserIDs.length && (
                <div>
                  Loading... ({Object.keys(monthlyData).length}/
                  {SalesUserIDs.length})
                </div>
              )}
          </div>
          <div className="d-flex align-items-center gap-2">
            Sort By:
            <Select value={sortParam} onChange={(value) => setSortParam(value)}>
              <Select.Option value="monthly-acheived-percentage">
                Monthly Achievement %
              </Select.Option>
              <Select.Option value="previous-day-acheived-percentage">
                Daily Achievement %
              </Select.Option>
            </Select>
            <Select
              value={view}
              onChange={(value) => {
                setView(value);
              }}
            >
              <Select.Option value="backend">Backend</Select.Option>
              <Select.Option value="frontend">Frontend</Select.Option>
            </Select>
          </div>
        </div>
        {view === "frontend" && (
          <Table
            columns={columns}
            dataSource={Array.from(loadedUsers)
              .map((userId) => {
                const monthylTarget = targets[userId] ?? 100;
                const previousDayTarget = monthylTarget / daysInThisMonth();

                const previousDayAcheived = [
                  ...(previousDayData[userId] ?? []),
                  ...(assignedToLeftOverMap.previousDay[userId] ?? []),
                ]?.reduce((prev, curr) => {
                  return (
                    prev +
                    ((parseFloat(curr?.total_estimate_amount ?? 0) ?? 0) -
                      parseFloat(curr?.total_refund_amount ?? 0) ?? 0)
                  );
                }, 0);
                const monthlyAcheived = [
                  ...(monthlyData[userId] ?? []),
                  ...(assignedToLeftOverMap.monthly[userId] ?? []),
                ]?.reduce((prev, curr) => {
                  return (
                    prev +
                    ((parseFloat(curr?.total_estimate_amount ?? 0) ?? 0) -
                      parseFloat(curr?.total_refund_amount ?? 0) ?? 0)
                  );
                }, 0);
                return {
                  key: userId,
                  name: userIdToNameMap[userId],
                  "monthly-target": monthylTarget,
                  "monthly-achieved": monthlyAcheived.toFixed(2) || 0,
                  "monthly-acheived-percentage": (
                    (monthlyAcheived / monthylTarget) *
                    100
                  ).toFixed(2),
                  "previous-day-target": parseFloat(
                    previousDayTarget.toFixed(2)
                  ),
                  "previous-day-achieved": previousDayAcheived.toFixed(2) || 0,
                  "previous-day-acheived-percentage": (
                    (previousDayAcheived / previousDayTarget) *
                    100
                  ).toFixed(2),
                };
              })
              .sort((a, b) => {
                console.log(a, b, sortParam);

                // Sort by monthly achievement percentage (parseFloat)
                return parseFloat(b[sortParam]) - parseFloat(a[sortParam]);
              })
              .filter((record) => !toHideIds.includes(record.key))
              .map((record, index) => ({
                ...record,
                sno: index + 1,
              }))}
            loading={loading}
            pagination={false}
          />
        )}
        {view === "backend" && (
          <Table
            columns={columns}
            dataSource={Object.keys(salesData)
              .map((userId) => {
                const monthylTarget = targets[userId] ?? 100;
                const previousDayTarget = monthylTarget / daysInThisMonth();

                return {
                  key: userId,
                  name: salesData[userId].business_manager_name,
                  "monthly-target": monthylTarget,
                  "monthly-achieved":
                    salesData[userId].current_month_estimates_sum ?? 0,
                  "monthly-acheived-percentage": (
                    ((salesData[userId].current_month_estimates_sum ?? 0) /
                      monthylTarget) *
                    100
                  ).toFixed(2),
                  "previous-day-target": parseFloat(
                    previousDayTarget.toFixed(2)
                  ),
                  "previous-day-achieved":
                    salesData[userId].today_estimates_sum ?? 0,
                  "previous-day-acheived-percentage": (
                    ((salesData[userId].today_estimates_sum ?? 0) /
                      previousDayTarget) *
                    100
                  ).toFixed(2),
                };
              })
              .sort((a, b) => {
                // Sort by monthly achievement percentage (parseFloat)
                return parseFloat(b[sortParam]) - parseFloat(a[sortParam]);
              })
              .filter((record) => !toHideIds.includes(record.key))
              .map((record, index) => ({
                ...record,
                sno: index + 1,
              }))}
            loading={loading}
            pagination={false}
          />
        )}
      </section>
    </main>
  );
}
